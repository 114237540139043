/* styles.css */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px; /* Ensure the modal has a max-width */
  width: 90%; /* Ensure the modal takes up most of the width but not more than max-width */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal h1 {
  text-align: center;
}

.modal p {
  text-align: center;
}

/* Hide the cl-footerAction element */
.cl-footerAction.cl-footerAction__signIn {
  display: none !important;
}

/* Hide the cl-footerAction element  cl-footerAction cl-footerAction__signUp 🔒️ cl-internal-1rpdi70 */
.cl-footerAction.cl-footerAction__signUp {
  display: none !important;
}

/* hide cl-headerSubtitle */
.cl-headerSubtitle {
  display: none !important;
}

